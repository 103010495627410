<template>
  <div class="customer-service-page">
    <zv-nav-bar :title="'建议反馈'"></zv-nav-bar>
    <div class="part-1">
      <van-loading color="#1989fa" v-if="isShow" />
      <div class="part-1-box qrcode-content" v-html="img"></div>
      <div class="part-1-box">
        长按识别二维码添加客服企业微信
      </div>
    </div>
    <div class="part-2">
      <van-button round type="info" @click="toPrevPage">返 回</van-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      img: ''
    }
  },
  created() {
    this.getData()
  },
  computed: {
    isShow() {
      return this.img === ''
    }
  },
  methods: {
    toPrevPage() {
      this.$router.go(-1)
    },
    getData() {
      this.$axios.get('/json/kf/index.json').then(res => {
        if (res.data.status === 1) {
          const data = res.data.data.length > 0 ? res.data.data[0]: null
          if (data) {
            let url = data.artUrl.substring(data.artUrl.indexOf('/json'))
            this.$axios.get(url).then(d => {
              if (d.data.status === 1) {
                this.img = d.data.data.content
              } else {
                this.img = '暂无数据'
              }
            })
          } else {
            this.img = '暂无数据'
          }
        } else {
          this.img = '暂无数据'
        }
      }).catch(e => {
        this.img = '暂无数据'
        return e
      })
    }
  }
}
</script>
<style scoped>
.customer-service-page {
  background: #f8f8f8;
  height: 100vh;
}
.customer-service-page .part-1 .part-1-box {
  width: 6rem;
  margin: auto;
  background: #fff;
  margin-top: 0.4rem;
  padding: 0.2rem;
  box-sizing: border-box;
  font-size: 0.37rem;
}
.customer-service-page .part-1 .part-1-box.qrcode-content {
  height:6.82rem;
  line-height:6.82rem;
  text-align: center;
}
.customer-service-page .part-1 .part-1-box >>> img {
  width: 100%;
  height: 100%;
  max-width: 100%
}
.customer-service-page .part-2 {
  text-align: center;
  margin-top: 2rem;
}
.customer-service-page .part-2 >>> .van-button--round {
  padding: 0.2rem;
  width: 90%;
  font-size: 0.4rem;
  height: auto;
}
.customer-service-page >>> .van-loading {
  top: 4.26rem;
  left: 4.66rem;
  width: 0.8rem;
}
.customer-service-page >>> .van-loading__spinner {
  width: 0.8rem;
  height: 0.8rem;
}
</style>